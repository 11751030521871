import keyboardPic from './keyboard.jpg';
import './App.css';

function App() {
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={keyboardPic} className="App-logo" alt="keyboard" />
        <div>
          <code>bryanny.codes</code>
          <br/>
          <br/>
          <a href="https://www.linkedin.com/in/bryanny/" >
            <myfont>Hola Mundo</myfont>
          </a>
          <br/>
          <br/>
          <a href="https://github.com/bryannyc/LibraryProject" >
            <myfont>Library Project</myfont>
          </a>
        </div>
       
      </header>
    </div>
  );
}

export default App;
